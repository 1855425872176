import {g} from '../helpers/global.js';
import {projects} from '../data/projects.js';

let modal = {
  isVisible: false,
  component: null
}

let initialState = {
  projects: [],
  project: g.newProject,
  modal
}

export const reducer = (state = initialState, action) => {
  let projects = [];
  switch (action.type) {
    case 'FETCH_PROJECTS':
      return {...state, projects: action.projects};

    case 'SET_CURRENT_PROJECT':
      return {...state, project: action.project};

    case 'CREATE_NEW_PROJECT':
      return {...state, project: action.project};

    case 'ADD_PROJECT':
      projects = g.deepCopy(state.projects);
      projects.push(action.project);
      return {...state, projects};

    case 'SAVE_PROJECT':
      return state;

    case 'DELETE_PROJECT':
      projects = g.deepCopy(state.projects);
      let index = g.findIndexByProp(state.projects, 'id', action.project.id);
      projects.splice(index, 1);
      return {...state, projects};

    case 'UPDATE_PROJECT':
      return {...state, project: action.project};

    case 'OPEN_MODAL':
      modal = {isVisible: true, component: action.component};
      return {...state, modal};

    case 'HIDE_MODAL':
      modal = {isVisible: false, component: null};
      return {...state, modal};

    default:
      return state;
  }
}
