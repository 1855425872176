import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ProjectRowControls from './ProjectRowControls.jsx';
const TOGGLE_ARROW_CLASSES = 'material-icons link toggle-arrow noselect ';

export default function ProjectRowCollapsible ({project, onDeleteProject, onDuplicateProject}) {
  let [collapsibleRowStyle, setCollapsibleRowStyle] =  useState({display: 'table-row'});
  let [toggleArrowClass, setToggleArrowClass] =  useState(TOGGLE_ARROW_CLASSES + 'arrow-up');

  let updateToggleArrowClasses = isToggleOff => {
    let toggleArrowClass = TOGGLE_ARROW_CLASSES + (isToggleOff ? 'arrow-up' : 'arrow-down');
    setToggleArrowClass(toggleArrowClass);
  };

  let updateCollapsibleRowStyle = isToggleOff => {
    let rowStyle = isToggleOff ?  {display: 'table-row'} : {display: 'none'};
    setCollapsibleRowStyle(rowStyle);
  }

  let toggleCollapsible = () => {
    let isToggleOff = collapsibleRowStyle.display === 'none';
    updateCollapsibleRowStyle(isToggleOff);
    updateToggleArrowClasses(isToggleOff);
  };

  return (
      <>
        <tr className="row-title" onClick={toggleCollapsible}>
          <th>{project.name}</th>
          <td></td>
          <td>{project.link}</td>
          <td>
            <i className={toggleArrowClass}>keyboard_arrow_down</i>
          </td>
        </tr>
        {
          project.children.map(child => (
            <tr key={child.id} style={collapsibleRowStyle} >
              <td>
                <Link to={'project/' + child.id}>
                  <span className="link" title={child.name}> {child.name} </span>
                </Link>
              </td>
              <td>
                <Link to={'project/' + child.id}>
                  <span className="link" title={child.cell}> {child.cell} </span>
                </Link>
              </td>
              <td>
                <a href={child.nextUrl} target="_blank" className="grey-text text-darken-1 link"> {child.nextUrl} </a>
              </td>
              <ProjectRowControls project={child} onDeleteProject={onDeleteProject} onDuplicateProject={onDuplicateProject} />
            </tr>
          ))
        }
      </>
  );
};
