import React, {useState, useEffect} from 'react';
import {g} from '../../helpers/global.js';
import Subevent from './Subevent.jsx';
import {events} from '../modules/events.jsx'

export default function ArrowEvent({onDeleteEvent, onSelectEvent, setEvent, subevents, selected, event, videoDuration, onSelectIcon}) {
  let [classes, setClasses]= useState('arrow-evt with-subevts evt');

  useEffect(() => {
    let classesCurr = 'arrow-evt with-subevts evt' + (selected == event.id ? ' active' : '');
    setClasses(classesCurr);
  }, [selected]);


  return (
    <div className={classes} onClick={onSelectEvent}>
      <div className="with-subevts-left">
        <i className="material-icons grey-text evt-icon" title="Stop event">double_arrow</i>
        <input type="number" placeholder="Start" className="time" name='startTime' value={event.startTime} step="0.1" onChange={(e)=>{events.setEvtProp(e,event,setEvent)}} min="1" max={Math.floor(videoDuration)}/>
        <input type="number" placeholder="End" className="time" name='endTime' value={event.endTime} step="0.1" onChange={(e)=>{events.setEvtProp(e,event,setEvent)}} min="1" max={Math.floor(videoDuration)}/>
      </div>

      <div className="with-subevts-right">
        {
          subevents.map((subevt, i) => {
            return <Subevent key={subevt.id}
                             subevent={subevt}
                             setSubevent={subevent => events.setSubevent(i ,event, subevent,setEvent)}
                             deleteSubevent={() => events.deleteSubevent(subevt,event,setEvent)}
                             onSelectIcon={e => onSelectIcon(e, subevt.icon)}
                             />
          })
        }
      </div>

      <div className="evt-ctrl">
        <i className="material-icons grey-text link" title="Delete whole event" onClick={onDeleteEvent}>delete</i>
      </div>
    </div>
  )
}
