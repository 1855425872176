import {api} from './api';

export const auth = {
  login: data => {
    return api.login(data)
      .then(response => {
        let user = JSON.stringify(response.data);
        window.localStorage.setItem('user', user);
        return Promise.resolve(true);
      })
      .catch(err => console.log('ERR login'))
      .finally(() => Promise.resolve(true));
  },

  logout: data => {
    return api.logout(data)
      .then(response => {
        // delete from storage
        window.localStorage.removeItem('user');
      })
      .catch(err => console.log('ERR logout'))
      .finally(() => window.location.href = '/');
  },

  isLoggedIn: () => {
    // return session id from storage
    return localStorage.getItem('user');
  }
}
