import React, {useMemo, useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';

let baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function StyledDropzone({uploadVideo, isSaved, videoHeight}) {
  baseStyle.height = videoHeight ? (videoHeight + 'px') : '450px';
  let dropzoneClasses = 'dropzone-container' + (isSaved ? '' : ' disabled');
  let [disabled, setIsDisabled] = useState(true);
  let [dropzoneData, setDropzoneData] = useState({
    status: "Drag 'n' drop a file here, or click to select file",
    icon: <i className="material-icons blue-text link">cloud_upload</i>
  });

  useEffect(() => {
    if (!isSaved) {
      setDropzoneData({
        status: <span className="grey-text text-lighten-1">You cannot upload video before saving the project.</span>,
        icon: <i className="material-icons grey-text text-lighten-2">highlight_off</i>
      });
    } else {
      setDropzoneData({
        status: "Drag 'n' drop a file here, or click to select file",
        icon: <i className="material-icons blue-text link">cloud_upload</i>
      });
      setIsDisabled(false);
    }
  }, [isSaved]);

  let onDropAccepted = file => {
    if (!isSaved) return;
    setDropzoneData({
      status: "Uploading video, please wait...",
      icon: <i className="material-icons uploading green-text link">import_export</i>
    });

    uploadVideo(new File(file, 'video.mp4'));
  }

  let onDropRejected = file => {
    setDropzoneData({
      status: "You can upload only one video file. Please, try again.",
      icon: <i className="material-icons red-text link">warning</i>
    });
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: 'video/mp4, video/webm', multiple: false, onDropAccepted, onDropRejected});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);

  return (
    <div className={dropzoneClasses}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} disabled={disabled}/>
        <div className="dropzone-body">
          <i className="material-icons blue-text">{dropzoneData.icon}</i>
          <p>{dropzoneData.status}</p>
        </div>
      </div>
    </div>
  );
}
