import React from 'react';
import {Link} from 'react-router-dom';
import ProjectRowControls from './ProjectRowControls.jsx';

export default function ProjectRowStatic ({project, onDeleteProject, onDuplicateProject}) {
  return (
    <tr className="row-title">
      <th>
        <Link to={'project/' + project.id}>
          <span className="link" title={project.name}> {project.name} </span>
        </Link>
      </th>
      <td> {project.cell} </td>
      <td>
        <a href={ project.nextUrl } target="_blank" className="grey-text text-darken-1 link"> { project.nextUrl }</a>
      </td>
      <ProjectRowControls project={project} onDeleteProject={onDeleteProject} onDuplicateProject={onDuplicateProject} />
    </tr>
  )
}
