import React, {Component} from 'react';
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export default class Editor extends Component {
  text = '';
  value = this.props.boardEvt.text;

  handleChange = (content) => {
    if (!this.props.boardEvt)
      return;
    this.text = content;

  }
  save = () => {
    this.props.setBoardEvtText(this.props.boardEvt, this.text)
  }

  render() {
    return (
      <div style={{opacity: this.props.boardEvt.opacityLevel + "%"}} className="suneditor-wrapper">
      <SunEditor
         setOptions={{
          height: "450px",
          width: "800px",
          opacity:"50%",
          buttonList: [
            [
              'undo',
              'redo',
              'fontSize',
              'formatBlock',
              'paragraphStyle',
              'bold',
              'underline',
              'italic',
              'strike',
              'fontColor',
              'hiliteColor',
              'textStyle',
              'removeFormat',
              'outdent',
              'indent',
              'align',
              'horizontalRule',
              'list',
              'lineHeight',
              'table',
              'link',
              'image',
              'showBlocks',
              'codeView',

            ]
          ]
        }}
        setContents={this.value} onChange={this.handleChange}/>
      <div className="editBoardEvtButton-wrapper">
        <button className="waves-effect waves-light btn" onClick={this.props.onEditBoardEvt} >Cancel</button>
        <button className="waves-effect waves-light btn" onClick={(e) => {this.props.onEditBoardEvt(e); this.save(); }}>Save</button>
      </div>
    </div>);
  }
};
