export const responsiveness = {
  hd: {
    innerWidth: 1920,
    innerHeight: 1009, // window.innerHeight without bookmarks
    videoWidth: 800,
    videoHeight: 450,
    ratio: 16 / 9,

    iconsBarHeight: 250,
    iconsBarWidth: 656,
    iconSquareWidth: 120,
    iconSquareHeight: 80,
    iconsPerColumn: 5,

    eventsHeight: 430,

    iconScaleX: 0.4,
    iconScaleY: 0.4
  },

  getVideoWidth: function () {
    return window.innerWidth * this.hd.videoWidth /  this.hd.innerWidth;
  },

  getVideoHeight: function (width) {
    let w = width || this.getVideoWidth();
    return w / this.hd.ratio; // keep the ratio
  },

  getIconsBarHeight: function () {
    return window.innerHeight * this.hd.iconsBarHeight / this.hd.innerHeight;
  },

  getIconSquareWidth: function () {
    let sw = this.hd.iconSquareWidth;
    let iconsBar = document.querySelector('.icons');
    if (iconsBar) sw = iconsBar.getBoundingClientRect().width * this.hd.iconSquareWidth / this.hd.iconsBarWidth;
    return sw;
  },

  getIconSquareHeight: function () {
    return window.innerHeight * this.hd.iconSquareHeight / this.hd.innerHeight;
  },

  getIconsPerColumn: function (iconsBarWidth) {
    return (window.innerHeight < 780 && window.innerWidth <= 1580) ? 7 : 5;
  },

  getEventsHeight: function () {
    return window.innerHeight * this.hd.eventsHeight / this.hd.innerHeight - 40; // 40 is the sum of the padding
  },

  calcIconScaleX: function (width) {
    let canvasWidth = width || this.getVideoWidth(); // canvas is video's overlay
    return canvasWidth * this.hd.iconScaleX / this.hd.videoWidth;
  },

  calcIconScaleY: function (height) {
    let canvasHeight = height || this.getVideoHeight(); // canvas is video's overlay
    return canvasHeight * this.hd.iconScaleY / this.hd.videoHeight;
  }
}
