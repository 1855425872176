import React, {Component} from 'react';
import Project from './components/project/Project.jsx';
import Projects from './components/projects/Projects.jsx';
import Login from './components/Login.jsx';
import PrivateRoute from './PrivateRoute.jsx';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

class App extends Component {
  render () {
    return (
      <Router>
        <div className="app">
          <Switch>
            <PrivateRoute path="/projects" component={Projects}></PrivateRoute>
            <PrivateRoute path="/project/:id" component={Project}></PrivateRoute>
            <Route path="/" component={Login}></Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
