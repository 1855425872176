import React, {Component} from 'react';
import Canvas from './Canvas.jsx';
import BoardEventOverlay from './BoardEventOverlay.jsx';
import {videoJSOptions} from '../../data/videoJSOptions.js';
import {responsiveness} from '../../helpers/responsiveness.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default class Video extends Component {
  setControlBar = () => {
    document.querySelector('.video-js .vjs-control-bar').style.display = 'flex';
    document.querySelector('.video-js .vjs-control-bar').style.bottom = '-30px';
    document.querySelector('.video-js .vjs-control-bar').style.background = 'black';
    document.querySelector('.video-js .vjs-control-bar').style.fill = '#fff';
  }

  render() {
    return (
      <div className="canvas-video-wrapper">
        <BoardEventOverlay boardEvt={this.props.boardEvt}
                           setBoardEvtText={this.props.setBoardEvtText}
                           videoHeight={this.props.videoHeight}
                           videoWidth={this.props.videoWidth}
                           onEditBoardEvt={this.props.onEditBoardEvt}
                           />

        <Canvas icons={this.props.icons}
                toggleIconsUpdate={this.props.toggleIconsUpdate}
                updateIcon={this.props.updateIcon}
                deleteIcons={this.props.deleteIcons}
                selectedIcon={this.props.selectedIcon}
                videoWidth={this.props.videoWidth}
                videoHeight={this.props.videoHeight}
                />
        <div className="video-wrapper" data-vjs-player>
          <video className="video video-js vjs-16-9" id='3Dstore'></video>
        </div>
      </div>
    );
  }

  componentDidMount() {
    let options = {...videoJSOptions};
    options.sources = [{  src: this.props.src   }];
    options.width = responsiveness.getVideoWidth();
    options.height = responsiveness.getVideoHeight();
    let player = videojs(document.getElementById('3Dstore'), options, () => {});
    player.on('loadedmetadata', () => {
        this.props.setPlayer(player);
    });
    player.on('timeupdate', this.props.onTimeUpdate);
    this.setControlBar();
  }
}
