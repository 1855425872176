import {
  api
} from '../api';
import {
  g
} from '../helpers/global.js';

function sortEvents(project) {
  project.allEvents.sort((evt1, evt2) => evt1.startTime - evt2.startTime);
};

export const fetchProjects = dispatch => {
  return dispatch => {
    return api.fetchProjects().then(response => {
        let projects = [];
        for (let i = 0; i < response.data.length; i++) {
          let project = JSON.parse(response.data[i].project);
          if (typeof project !== 'object')
            project = JSON.parse(project);
          project.id = response.data[i].id;
          if (!project.key) {
            project.key = Date.now();
          }
          projects.push(project);
        }
        dispatch({
          type: 'FETCH_PROJECTS',
          projects
        });
        return Promise.resolve(projects);
      })
      .catch(err => Promise.reject(false));
  }
};

export const createNewProject = dispatch => {
  return dispatch => {
    let project = {
      ...g.newProject
    };
    dispatch({
      type: 'CREATE_NEW_PROJECT',
      project
    });
  };
};

export const setCurrentProject = (project, dispatch) => {
  sortEvents(project);
  return dispatch => {
    dispatch({
      type: 'SET_CURRENT_PROJECT',
      project
    });
  };
};

export const saveProject = (project, dispatch) => {

  return dispatch => {
    return api.saveProject({
        project: JSON.stringify(project),
        cell: project.cell
      })
      .then(response => {
        if (response.data !== 'Updated') {
          project.id = response.data;
          dispatch({
            type: 'SET_CURRENT_PROJECT',
            project
          });
        }
        return Promise.resolve(project);
      })
      .catch(err => Promise.reject(false))
  };
};

export const deleteProject = (project, dispatch) => {
  return dispatch => {
    return api.deleteProject({id: project.id})
      .then(response => {
        dispatch({
          type: 'DELETE_PROJECT',
          project
        });
      })
      .catch(err => console.log('ERR deleteProject'))
  };
};

export const duplicateProject = (project, dispatch) => {
  return dispatch => {
    return api.saveProject({
        project: JSON.stringify(project)
      })
      .then(response => {
        project.id = response.data;
        dispatch({
          type: 'SET_CURRENT_PROJECT',
          project
        });
      })
      .catch(err => console.log('ERR duplicateProject'))
      .finally(() => {
        dispatch({
          type: 'ADD_PROJECT',
          project
        });
      });
  };
};

export const updateProject = (project, dispatch) => {
  sortEvents(project);
  return dispatch => {
    dispatch({
      type: 'UPDATE_PROJECT',
      project
    });
  }
};

export const openModal = (component, dispatch) => {
  return dispatch => dispatch({
    type: 'OPEN_MODAL',
    component
  });
};

export const hideModal = dispatch => {
  dispatch({
    type: 'HIDE_MODAL'
  });
};
