import React, {Component} from 'react';
import StopEvent from './StopEvent.jsx';
import BoardEvent from './BoardEvent.jsx';
import ArrowEvent from './ArrowEvent.jsx';
import AutoRedirectEvent from './AutoRedirectEvent.jsx';

export default class Events extends Component {
  createStopEvent = () => {
    this.props.onCreateEvent({id: `stopevt-${new Date().getTime()}`,  startTime: 1, endTime: 1, name: '', type: 'stop', subevents: []});
  };

  createArrowEvent = () => {
    this.props.onCreateEvent({id: `arrowevt-${new Date().getTime()}`, startTime: 1, endTime: 1, name: '',  type: 'arrow', subevents: []});
  };

  createBoardEvent = () => {
    this.props.onCreateEvent({id: `boardevt-${new Date().getTime()}`, startTime: 1, endTime: 1, name: '',  text: '', type: 'board', subevents: []});
  };

  createAutoRedirectEvent = () => {
    this.props.onCreateEvent({id: `autoredirectevt-${new Date().getTime()}`, startTime: 1, endTime: 1, name: '',  text: '', type: 'autoRedirect', subevents: []});
  };

  render() {
    return (
      <div className="events card-panel">
        <div className="events-title">
          <h6>Events</h6>
          <div className="events-btns">
            <button className="btn grey-text white text-darken-1" onClick={this.createStopEvent} >
              <i className="material-icons link grey-text" title="Create stop event">traffic</i>
            </button>
            <button className="btn grey-text white text-darken-1" onClick={this.createArrowEvent}>
              <i className="material-icons link grey-text" title="Create arrow event">double_arrow</i>
            </button>
            <button className="btn grey-text white text-darken-1" onClick={this.createBoardEvent}>
              <i className="material-icons link grey-text" title="Create board event">post_add</i>
            </button>
            <button className="btn grey-text white text-darken-1" onClick={this.createAutoRedirectEvent}>
              <i className="material-icons link grey-text" title="Create auto redirect event">insert_link</i>
            </button>
          </div>
        </div>

        <div className="events-body">
          {
            this.props.events.length ?
              this.props.events.map((event, idx) => {
                switch(event.type) {
                  case 'stop':
                    return (
                      <StopEvent selected={this.props.selected}
                                 event={event}
                                 key={event.id}
                                 subevents={event.subevents}
                                 setEvent={this.props.onSetEvent}
                                 onSelectEvent={() => this.props.onSelectEvent(event)}
                                 onDeleteEvent={e => this.props.onDeleteEvent(e, event)}
                                 videoDuration={this.props.videoDuration}
                                 onSelectIcon={this.props.onSelectIcon}
                                 />
                    )
                  case 'arrow':
                    return <ArrowEvent selected={this.props.selected}
                                       event={event}
                                       key={event.id}
                                       subevents={event.subevents}
                                       setEvent={this.props.onSetEvent}
                                       onSelectEvent={() => this.props.onSelectEvent(event)}
                                       onDeleteEvent={e => this.props.onDeleteEvent(e, event)}
                                       videoDuration={this.props.videoDuration}
                                       onSelectIcon={this.props.onSelectIcon}
                                       />
                  case 'board':
                    return <BoardEvent selected={this.props.selected}
                                       event={event}
                                       id={event.id}
                                       key={event.id}
                                       setEvent={this.props.onSetEvent}
                                       onSelectEvent={() => this.props.onSelectEvent(event)}
                                       onDeleteEvent={e => this.props.onDeleteEvent(e, event)}
                                       videoDuration={this.props.videoDuration}
                                       onEditBoardEvt={this.props.onEditBoardEvt}
                                       />
                  case 'autoRedirect':
                    return <AutoRedirectEvent selected={this.props.selected}
                                       event={event}
                                       id={event.id}
                                       key={event.id}
                                       setEvent={this.props.onSetEvent}
                                       onSelectEvent={() => this.props.onSelectEvent(event)}
                                       onDeleteEvent={e => this.props.onDeleteEvent(e, event)}
                                       videoDuration={this.props.videoDuration}
                                       />
                  default:
                    return null;
                }
              })
              :
            <p className="no-events">There are no events yet. </p>
          }
        </div>
      </div>
    )
  }
}
