import React from 'react';
import {auth} from '../auth.js';
import logo from '../assets/images/logo.png';

export default function Navigation ({saveProject, backToProjects}) {
  let logout = () => {
    auth.logout();
  }

  return (
    <div id="navigation" className="eyesee-header">
      <div className="logo">
        <img src={logo} alt="EyeSee"/>
      </div>

      <ul className="menu">
      {saveProject ?
        <li className="menu-item">
          <i className="material-icons grey-text link" title="Save" onClick={saveProject}> save </i>
        </li>
        : '' }
        {backToProjects ?
        <li className="menu-item">
          <i className="material-icons grey-text link" title="All projects" onClick={backToProjects}> view_list </i>
        </li>
          : '' }
        <li className="menu-item">
          <i className="material-icons grey-text link" title="Log Out" onClick={logout}> power_settings_new </i>
        </li>
      </ul>
    </div>
  )
}
