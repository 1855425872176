import React from 'react';

export default function DeleteProject({deleteProject, hideModal, project}) {
  return (
    <div className="delete-project card">
      <div className="card-title">
        <h5>Delete Project</h5>
      </div>

      <div className="card-content">
        <p>You are about to delete a project. Are you sure? </p>
      </div>

      <div className="card-footer m-2">
        <button className="btn btn-outlined white black-text text-lighten-2" onClick={hideModal}>Cancel</button>
        <button className="btn red lighten-2" onClick={() => deleteProject(project)}>Delete</button>
      </div>
    </div>
  )
}
