import React from 'react';
import {g} from '../../helpers/global.js';

export default function Data ({project, updateProject}) {
  let setValue = e => {
    let projectUpdated = g.deepCopy(project);
    projectUpdated[e.target.name] = e.target.value;
    updateProject(projectUpdated);
  }

  let toggleChecked = e => {
    let projectUpdated = g.deepCopy(project);
    projectUpdated[e.target.name] = projectUpdated[e.target.name] ? 0 : 1;
    updateProject(projectUpdated);
  }

  return (
    <div className="project-data-form">
      <div className="ctrl input-field">
        <input type="text" placeholder="Name"  name="name" value={project.name} onChange={setValue}/>
      </div>
      <div className="ctrl input-field">
        <input type="text" placeholder="Cell"  name="cell" value={project.cell} onChange={setValue}/>
      </div>
      <div className="ctrl input-field user-controls">
        <input type="checkbox" checked={project.userControls} name="userControls" id="userControls" onChange={toggleChecked}/>
        <label htmlFor="userControls">User controls</label>
      </div>
      <div className="ctrl input-field next-url">
        <input type="text" placeholder="Next url" name="nextUrl"  value={project.nextUrl} onChange={setValue}/>
      </div>
      <div className="ctrl input-field reporting-problem">
        <input type="checkbox" checked={project.isReportingProblem} name="isReportingProblem" id="isReportingProblem" onChange={toggleChecked}/>
        <label htmlFor="userControls">Issue report</label>
      </div>
    </div>
  );
}
