import React, {useRef, useEffect} from 'react';

export default function Preview({link, style, hideLink}) {
  useEffect(() => {
    let link = document.getElementById('link');
    link.select();
  });

  let copyLink = () => {
    let link = document.getElementById('link');
    link.select();
    document.execCommand('copy');
  }

  let previewLink = e => {
    e.preventDefault();
    e.stopPropagation();
    window.open(link, '_blank')
  }

  return (
    <div className="link-preview" style={style} >
      <input type="text" defaultValue={link} id="link" />
      <i className="material-icons link preview-link" onClick={copyLink}>file_copy</i>

      <a href={link} target="_blank" className="preview-link" onClick={previewLink}>
        <i className="material-icons link open-link preview-link">open_in_new</i>
      </a>
    </div>
  );
}
