import React from 'react';
import ReactDOM from 'react-dom';
import './assets/style/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import {store} from './redux/store.js';
import {Provider} from 'react-redux';

M.AutoInit();
ReactDOM.render(<Provider store={store}> <App /> </Provider>, document.getElementById('root'));

serviceWorker.unregister();
