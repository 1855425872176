import React, {useState, useEffect} from 'react';
import ProjectRowStatic from './ProjectRowStatic.jsx';
import ProjectRowCollapsible from './ProjectRowCollapsible.jsx';

export default function ProjectsList ({projects, onDeleteProject, onDuplicateProject}) {
  let [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    let tableRows = groupProjectsByName(projects);
    setTableRows(tableRows);
  }, [projects]);

  function groupProjectsByName (projects) {
    let nameMap = {};
    let tableRows = [];

    for (let project of projects) {
      let row = {...project, children: [], isCollapssible: false};
      if (isProjectMapped(project)) {
        insertDuplicatedProject(project);
      } else {
        insertOriginalProject(row, project);
      }
    }

    function insertOriginalProject(row, project) {
      row.children.push(project);
      tableRows.push(row);
      nameMap[project.name] = tableRows.length;
    };

    function insertDuplicatedProject(project) {
      let index = getProjectsIndex(project);
      tableRows[index].isCollapssible = true;
      tableRows[index].children.push(project);
    };

    function getProjectsIndex (project) {
      return nameMap[project.name] - 1;
    };

    function isProjectMapped (project) {
      return (typeof nameMap[project.name] !== 'undefined');
    };

    return tableRows;
  };

  return (
    <tbody>
      {
       tableRows.map(project => project.isCollapssible ?
                               <ProjectRowCollapsible project={project} key={project.id} onDeleteProject={onDeleteProject} onDuplicateProject={onDuplicateProject}/> : 
                               <ProjectRowStatic project={project} key={project.id} onDeleteProject={onDeleteProject} onDuplicateProject={onDuplicateProject}/>)
      }
    </tbody>
  )
}
