import React, {Component} from 'react';
import Modal from '../modal/Modal.jsx';
import BackVideoModal from '../modal/BackVideoModal.jsx';
import Navigation from '../Navigation.jsx';
import Events from './Events.jsx';
import Data from './Data.jsx';
import Icons from './Icons.jsx';
import Video from './Video.jsx';
import VideoSidebar from './VideoSidebar.jsx';
import Canvas from './Canvas.jsx';
import Dropzone from './Dropzone.jsx';
import {connect} from 'react-redux';
import {fetchProjects, setCurrentProject, saveProject, updateProject, openModal, hideModal} from '../../redux/actions.js';
import {g} from '../../helpers/global.js';
import island from '../../assets/island.mp4';
import {api} from '../../api';
import M from 'materialize-css';
import {responsiveness} from '../../helpers/responsiveness.js';
import Preview from './Preview.jsx';

class Project extends Component {
  player = null;
  threshold = 0.3;

  constructor(props){
    super(props);
    this.state = {
      videoWidth: responsiveness.getVideoWidth(),
      videoHeight: responsiveness.getVideoHeight(),
      selectedEvent: -1,
      selectedIcon: -1,
      icons: [],
      toggleIconsUpdate: true,
      boardEvt: null,
      videoDuration: 0,
      isSaved: false,
      backupIcons: [],
      iconsDisabled: true,
      link: {
        url: '',
        top: '0px',
        left: '0px'
      }
    }
  }

  setPlayer = player => {
    this.player = player;
    this.setState({videoDuration: player.duration()});
    player.currentTime(0);
    let project = g.deepCopy(this.props.project);
    project.videoWidth = document.getElementById('3Dstore_html5_api').offsetWidth;
    project.videoHeight = document.getElementById('3Dstore_html5_api').offsetHeight;
    this.props.updateProject(project);
  };

  setProject = () => {
    this.props.fetchProjects()
      .then(projects => {
        let project = projects.find(project => project.id == this.id);
        this.props.setCurrentProject(project);
        this.initIcons(project);
        this.setState({isSaved: !!project.id});
      });
  };

  backToProjects = () => {
    this.props.history.push('/projects');
  };

  initIcons = project => {
    let icons = [];
    project.allEvents.forEach(evt => {
      evt.subevents.forEach(subevt => {
        let icon = {
          startTime: subevt.startTime,
          endTime: subevt.endTime,
          subevtID: subevt.id,
          eventID: evt.id,
          ...subevt.icon
        }
        icons.push(icon);
      });
    });
    this.setState({icons, backupIcons: icons, toggleIconsUpdate: !this.state.toggleIconsUpdate});
  };

  updateIcon = allIconData => {
    let project = g.deepCopy(this.props.project);
    let evtIdx = g.findIndexByProp(this.props.project.allEvents, 'id', allIconData.eventID);
    let evt = this.props.project.allEvents[evtIdx];
    let subevtIdx = g.findIndexByProp(evt.subevents, 'id', allIconData.subevtID);
    let subevt = g.deepCopy(evt.subevents[subevtIdx]);
    let currVideoWidth = document.querySelector('.project-video').offsetWidth;
    let currVideoHeight = document.querySelector('.project-video').offsetHeight;
    subevt.icon = {
      ...subevt.icon,
      createdVideoHeight:currVideoHeight,
      createdVideoWidth:currVideoWidth,
      top: allIconData.top,
      left: allIconData.left,
      scaleX: allIconData.scaleX,
      scaleY: allIconData.scaleY,
      rotate: allIconData.rotate,
      angle: allIconData.angle,
      width: allIconData.width,
      height: allIconData.height
    }
    evt.subevents[subevtIdx] = subevt;
    project.allEvents[evtIdx] = evt;
    this.props.updateProject(project);

    let backupIcons = [...this.state.backupIcons]
    let bIconIdx = backupIcons.findIndex(bIcon => bIcon.id === subevt.icon.id)
    let icon = {
      ...backupIcons[bIconIdx],
      createdVideoHeight:currVideoHeight,
      createdVideoWidth:currVideoWidth,
      top: allIconData.top,
      left: allIconData.left,
      scaleX: allIconData.scaleX,
      scaleY: allIconData.scaleY,
      rotate: allIconData.rotate,
      angle: allIconData.angle,
      width: allIconData.width,
      height: allIconData.height
    };

    backupIcons.splice(bIconIdx,1,icon)
    this.setState({backupIcons});
  }

  createEvent = evt => {
    this.player.pause();
    evt.startTime = parseFloat(this.player.currentTime().toFixed(1));
    evt.endTime = parseFloat((parseFloat(evt.startTime) + 0.1).toFixed(1));
    let project = g.deepCopy(this.props.project);
    project.allEvents.push(evt);
    this.props.updateProject(project);
    let boardEvt = evt.type === 'board' ? evt: null;
    this.setState({selectedEvent: evt.id, boardEvt, iconsDisabled: false});
  }

  deleteEvent = (e, evt) => {
    e.stopPropagation();
    const resp = window.confirm("Are you sure ?!");
    if (resp) {
      let project = g.deepCopy(this.props.project);
      let idx = g.findIndexByProp(project.allEvents, 'id', evt.id);
      project.allEvents.splice(idx, 1);
      this.setState({selectedEvent: -1, iconsDisabled: true, boardEvt: null});
      this.props.updateProject(project);
      this.deleteAllEvtIcons(evt.id);
      // this.setState({selectedEvent: -1, iconsDisabled: true, boardEvt: null});
      }

  }

  deleteAllEvtIcons = evtID => {
    let allIcons = g.deepCopy(this.state.icons);
    let icons = allIcons.filter(icon => icon.eventID !== evtID);
    this.setState({icons, toggleIconsUpdate: !this.state.toggleIconsUpdate});
  }

  uploadVideo = (file, isBack = false) => {
    let project = g.deepCopy(this.props.project);

    let formData = new FormData();
    formData.append('project_id', this.props.project.id);
    formData.append('file', file);
    api.uploadMedia(formData)
      .then(response => {
        if (!isBack)
          project.video = response.data;
        else {
          project.backVideo = response.data;
          this.props.hideModal();
          M.toast({html: 'Back video successfully saved!', classes: 'green', displayLength: 5000});
        }
        this.props.updateProject(project);
        this.props.saveProject(project);
      });
  }

  saveProject = () => {

    this.props.saveProject(this.props.project)
      .then(response => {
        M.toast({html: 'Project successfully saved!', classes: 'green', displayLength: 5000});
        this.setState({isSaved: true})
      }).catch(err => {
        M.toast({html: 'Failed to save project ', classes: 'red', displayLength: 5000});

       });
  }

  selectEvent = evt => {
    let boardEvt = evt.type === 'board' ? evt : false;
    let iconsDisabled = evt.type === 'board' ? true : false;
    let t = parseFloat(evt.startTime);
    this.player.currentTime(t);
    this.player.pause();
    this.setState({selectedEvent: evt.id, boardEvt, iconsDisabled});

  }

  editBoardEvt = (e,evt) => {
    e.stopPropagation()
    let boardEvt = this.state.boardEvt
    boardEvt.showEdit = !this.state.boardEvt.showEdit
    this.setState({boardEvt});

  }


  createSubevent = icon => {
    let project = g.deepCopy(this.props.project);
    let evtIdx = g.findIndexByProp(project.allEvents, 'id', this.state.selectedEvent);
    let canvasPosition = document.querySelector('.canvas-container').getBoundingClientRect();
    if (evtIdx === - 1) return;
    let evt = project.allEvents[evtIdx];
    if (evt.type === 'board') return;
    let newSubevent = {
      name: '',
      label: '',
      labelVisibility:'always',
      icon: {
        id: `icon-${new Date().getTime()}`,
        path: icon.path,
        top: icon.top - canvasPosition.top,
        left: icon.left - canvasPosition.left,
        width: icon.width,
        height: icon.height,
        scaleX: this.iconScaleX,
        scaleY: this.iconScaleY,
        createdVideoWidth: icon.createdVideoWidth,
        createdVideoHeight: icon.createdVideoHeight
      },
      id: `subevt-${new Date().getTime()}`,
      next: 'dl',
      startTime: evt.startTime,
      endTime: evt.endTime,
      directLink: '',
      introStart: '',
      introEnd: '',
      continueAt: '',
      eventID: evt.id,
      isExit: false,
      isBack: false
    };
    evt.subevents.push(newSubevent);
    this.addIcon(newSubevent, icon);
    this.props.updateProject(project);
  };

  addIcon = subevt => {

    let icons = g.deepCopy(this.state.icons);
    let backupIcons = g.deepCopy(this.state.backupIcons);
    let newIcon = {
      startTime: subevt.startTime,
      endTime: subevt.endTime,
      subevtID: subevt.id,
      eventID: subevt.eventID,
      ...subevt.icon
      }
     icons.push(newIcon);
     backupIcons.push(newIcon);
     this.setState({icons, toggleIconsUpdate: !this.state.toggleIconsUpdate, backupIcons});
    }

  checkEvent = evt => {
    let startTime = parseFloat(evt.startTime);
    let endTime = parseFloat(evt.endTime);

    if (startTime > this.player.duration()) {
      startTime = this.player.duration();
    }

    if (startTime > endTime) {
      endTime = startTime + 0.1 <= this.player.duration() ? startTime + 0.1 : startTime;
    }
    return {
      ...evt,
      startTime,
      endTime
    }
  }

  onSetEvent = evt => {
    let project = g.deepCopy(this.props.project);
    let evtIdx = g.findIndexByProp(project.allEvents, 'id', evt.id);
    project.allEvents[evtIdx] = evt;
    this.props.updateProject(project);
    this.updateIconsVisibility();
  }

  setBoardEvtText = (boardEvt, text) => {
    if (!boardEvt) return;
    let project = g.deepCopy(this.props.project);
    boardEvt.text = text;
    let evtIdx = g.findIndexByProp(this.props.project.allEvents, 'id', boardEvt.id);
    project.allEvents[evtIdx] = boardEvt;
    this.props.updateProject(project);
    this.setState({boardEvt});
  }

  selectIcon = (e, icon) => {
    this.setState({selectedIcon: icon.id});
  }

  changeMainVideo = () => {
    let project = g.deepCopy(this.props.project);
    if (!project.video) return;
    this.setState({isSaved: !!project.id});
    api.deleteMedia({file: project.video, project_id: project.id})
      .then(response => {
        project.video = '';
        this.props.saveProject(project);
        this.props.updateProject(project);
      })
      .catch(err => console.log('delete media'));
  }

  changeBackVideo = file => {
    let project = g.deepCopy(this.props.project);
    if (project.backVideo) {
      api.deleteMedia({file: project.backVideo, project_id: project.id})
      .then(response => {
        this.uploadVideo(file, true);
      })
      .catch(err => console.log('err ', err))
    } else {
      this.uploadVideo(file, true);
    }
  }

  openBackVideoModal = () => {
    let project = this.props.project;
    this.props.openModal(<BackVideoModal changeBackVideo={this.changeBackVideo} project={project} hideModal={this.props.hideModal}/>);
  }

  updateIconsVisibility = () => {
    let ct = this.player.currentTime();
    let icons = [];
    this.setState({boardEvt: null});
    for (let i = 0; i < this.props.project.allEvents.length; i++) {
      let evt = this.props.project.allEvents[i];
      let startTime = parseFloat(evt.startTime);

      if (evt.type === 'arrow' && startTime <= ct && parseFloat(evt.endTime) >= ct) {
        evt.subevents.forEach(subevt => {
          let icon = this.state.backupIcons.find(icon => icon.id === subevt.icon.id);
          if (icon) icons.push(icon);
        });
      }

      if (evt.type === 'stop' && ((ct === startTime && this.player.paused()) || (ct > startTime && ct - this.threshold < startTime && !this.player.paused()))) {
        evt.subevents.forEach(subevt => {
          let icon = this.state.backupIcons.find(icon => icon.id === subevt.icon.id);
          if (icon) icons.push(icon);
        });
      }

      if (evt.type === 'board' && ((ct === startTime && this.player.paused()) || (ct > startTime && ct - this.threshold < startTime && !this.player.paused()))) {
        this.setState({boardEvt: evt});
      }
    }
    this.setState({icons, toggleIconsUpdate: !this.state.toggleIconsUpdate})
  }

  adjustDimensions = () => {
    let projectVideoEl = document.querySelector('.project-video');
    let projectContentEl = document.querySelector('.project-content.card-panel');
    let projectDataEl = document.querySelector('.project-data');
    let eventsEl = document.querySelector('.events');
    let videoSidebarEl = document.querySelector('.video-sidebar');
    projectVideoEl.style.width = this.state.videoWidth + 'px';
    projectVideoEl.style.height = this.state.videoHeight + 'px';
    projectContentEl.style.height = this.state.videoHeight + 'px';
    videoSidebarEl.style.height = this.state.videoHeight + 40 +'px';
    projectDataEl.style.width = `calc(100% - ${this.state.videoWidth}px)`;
    eventsEl.style.height = responsiveness.getEventsHeight() + 'px';
    this.iconScaleX = responsiveness.calcIconScaleX();
    this.iconScaleY = responsiveness.calcIconScaleY();
  }

  setActiveEventTime = () => {
    let project =  g.deepCopy(this.props.project);
    if (this.state.selectedEvent !== -1) {
      let idx = project.allEvents.findIndex(evt => evt.id === this.state.selectedEvent);
      let evt = project.allEvents[idx];
      evt.startTime = this.player.currentTime().toFixed(1);
      evt = this.checkEvent(evt);
      project.allEvents[idx] = evt;
      this.props.updateProject(project);
    }
  }

  previewLink = (e, url) => {
  let link = {
    link: `https://api.store.eyeseeshopping.com/app/register?project=${this.props.project.name}-${this.props.project.cell}&userid=xxx`,
    style: {
      top: (e.clientY + 10) + 'px',
      left: e.clientX + 'px',
      display: 'flex'
    }
  }
  this.setState({link});
  document.addEventListener('click', this.hideLink);
}

hideLink = e => {
  e.preventDefault();
  e.stopPropagation();
  if (e.target.classList.contains('preview-link')) return;
  let link = {
    link: '',
    style: {
      top: '0px',
      left: '0px',
      display: 'none'
    }
  }
  this.setState({link});
  document.removeEventListener('click', this.hideLink);
}

  render() {
    const {project, updateProject} = this.props;
    let isSaved = project.id;
    return (
      <div id="project">
        <Modal />
        <Navigation saveProject={this.saveProject} backToProjects={this.backToProjects}/>

        <div className="project-body">
           <div className="project-content card-panel">
             <VideoSidebar previewStore={this.previewLink}
                           openBackVideoModal={this.openBackVideoModal}
                           changeMainVideo={this.changeMainVideo}
                           setActiveEventTime={this.setActiveEventTime}
                           />
             <div className="project-video">
               {
                 project.video ?
                 <Video src={project.video}
                        icons={this.state.icons}
                        setPlayer={this.setPlayer}
                        toggleIconsUpdate={this.state.toggleIconsUpdate}
                        updateIcon={this.updateIcon}
                        selectedIcon={this.state.selectedIcon}
                        boardEvt={this.state.boardEvt}
                        setBoardEvtText={this.setBoardEvtText}
                        onTimeUpdate={this.updateIconsVisibility}
                        videoWidth={this.state.videoWidth}
                        videoHeight={this.state.videoHeight}
                        onEditBoardEvt={this.editBoardEvt}
                        />
                 :
                 <Dropzone uploadVideo={this.uploadVideo} isSaved={this.state.isSaved} videoHeight={this.state.videoHeight}/>
               }
             </div>
             <div className="project-data">
               <Data project={this.props.project} updateProject={updateProject} />
               <Icons createSubevent={this.createSubevent} disabled={this.state.iconsDisabled}/>
             </div>
           </div>
          <Preview {...this.state.link}></Preview>
          <Events events={project.allEvents}
                  onCreateEvent={this.createEvent}
                  onSetEvent={this.onSetEvent}
                  onDeleteEvent={this.deleteEvent}
                  onSelectEvent={this.selectEvent}
                  selected={this.state.selectedEvent}
                  videoDuration={this.state.videoDuration}
                  onSelectIcon={this.selectIcon}
                  onEditBoardEvt={this.editBoardEvt}
                  />
        </div>
      </div>
    )
  };

  componentDidMount() {
    this.adjustDimensions();
    document.documentElement.classList.add('lightgrey-bgd');
    api.fetchIcons();
    this.props.setCurrentProject(g.newProject);
    this.id = this.props.match.params.id;
    if (Number(this.id) != this.id && this.id !== 'create-new' ) {
      this.props.history.push('/projects');
      return;
    }
    if (Number(this.id) == this.id) {
      this.setProject();
    }
  }

  componentWillUnmount () {
    document.documentElement.classList.remove('lightgrey-bgd');
  }
}

const mapStateToProps = state => {
  return {
    project: state.project
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchProjects: () => dispatch(fetchProjects()),
    setCurrentProject: project => dispatch(setCurrentProject(project)),
    saveProject: project => dispatch(saveProject(project, dispatch)),
    updateProject: project => dispatch(updateProject(project), dispatch),
    openModal: component => dispatch(openModal(component, dispatch)),
    hideModal: () => dispatch(hideModal)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);
