import React, {useState, useEffect} from 'react';
import {events} from '../modules/events.jsx'

export default function AutoRedirectEvent ({onDeleteEvent, selected, onSelectEvent, event, setEvent, id, videoDuration}) {
  let [classes, setClasses]= useState('board-evt evt');

  useEffect(() => {
    let classesCurr = 'board-evt evt' + (selected == id ? ' active' : '');
    setClasses(classesCurr);
  }, [selected]);


  return (
    <div className={classes} onClick={onSelectEvent}>
      <i className="material-icons grey-text evt-icon">insert_link</i>
      <input type="number" placeholder="Time" className="time" name="startTime" onChange={(e)=>{events.setEvtProp(e,event,setEvent)}}  value={event.startTime} step="0.1" min="1" max={Math.floor(videoDuration)}/>
      <input type="text" placeholder="Name" className="redirectName" name="name" onChange={(e)=>{events.setEvtProp(e,event,setEvent)}} value={event.name}/>
      <input type="text" placeholder="Auto-redirect link" name="autoRedirectLink" onChange={(e)=>{events.setEvtProp(e,event,setEvent)}}  value={event.autoRedirectLink}/>
      <i className="material-icons grey-text link" onClick={onDeleteEvent} title="Delete whole event" > delete </i>
    </div>
  );
}
