import React from 'react';

export default function VideoSidebar(props) {
  return (
    <div className="video-sidebar">
      <button onClick={props.changeMainVideo} className="btn grey-text white" title="Change main video">
         <i className="material-icons link grey-text">filter_1</i>
      </button>

      <button onClick={props.openBackVideoModal} className="btn grey-text white" title="Set back video">
         <i className="material-icons link grey-text">filter_2</i>
      </button>

      <button onClick={props.previewStore} className="btn grey-text white preview-link" title="Preview">
         <i className="material-icons link grey-text preview-link">link</i>
      </button>

      <button onClick={props.setActiveEventTime} className="btn grey-text white selected-evt-time" title="Set selected event's time">
         <i className="material-icons link grey-text">access_time</i>
      </button>
    </div>
  )
}
