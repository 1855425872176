export class Draggable {
  constructor(element, options = {}, dropzone) {

    this.element = document.getElementById(element.id);
    this.options = options;
    this.dropzone = dropzone;

    this.initialPositionX = 0;
    this.initialPositionY = 0;
    this.currPositionX = 0;
    this.currPositionY = 0;
    this.element.onmousedown = this.startDragging;
    this.element.classList.add('draggable-item');
  }

  startDragging = e => {
    e = e || window.event;
    e.preventDefault();

    this.initialPositionX = e.clientX;
    this.initialPositionY = e.clientY;
    this.offsetX =  e.offsetX;
    this.offsetY = e.offsetY;

    this.element.style.top = this.initialPositionY - this.offsetY  + 'px';
    this.element.style.left = this.initialPositionX - this.offsetX  + 'px';
    this.element.style.position = "fixed";

    document.onmousemove = this.drag;
    document.onmouseup = this.stopDragging;
    if (this.options.onStartDragging)
      this.options.onStartDragging(this.element, this.options.data, this.dropzone);
  }

  drag = e => {
    e = e || window.event;
    e.preventDefault();
    
    this.currPositionX =  e.clientX;
    this.currPositionY =  e.clientY;
    let top = (this.currPositionY - this.offsetY);
    let left = (this.currPositionX - this.offsetX);

    this.element.style.top = top  + 'px';
    this.element.style.left = left + 'px';
    this.element.style.cursor = 'grabbing';
    if (this.options.onDrag)
      this.options.onDrag(this.element, this.options.data, this.dropzone);
  }

  stopDragging = e => {
    document.onmousemove = null;
    document.onmouseup = null;
    this.element.style.cursor = 'grab';
    if (this.options.onStopDragging)
      this.options.onStopDragging(this.element, this.options.data, this.dropzone);
  }
}
