import React from 'react';
import {auth} from '../auth.js';
import M from 'materialize-css';
import logo from '../assets/images/logo-red.png';

export default function Login(props) {
  let login = e => {
    e.preventDefault();
    e.stopPropagation();

    let user = {
      username: document.getElementById('login-name').value,
      pass: document.getElementById('login-pass').value
    }

    if (!user.username || !user.pass) {
      M.toast({html: 'Both parameters are required', classes: 'red', displayLength: 5000});
      return;
    }

    auth.login(user)
      .then(response => {
        window.location.href='/projects';
      })
      .catch(err => {
        M.toast({html: 'Wrong credentials!', classes: 'red', displayLength: 8000});
      })
  }

  return (
    <div className="login">
      <div className="logo-wrapper">
        <img src={logo} alt="EyeSee" />
      </div>
      <form onSubmit={login}>
        <div className="input-field">
          <i className="material-icons prefix">account_box</i>
          <input type="text" id="login-name"/>
          <label htmlFor="icon-prefix">Username</label>
        </div>

        <div className="input-field">
          <i className="material-icons prefix">lock</i>
          <input type="password" id="login-pass" />
          <label htmlFor="icon-prefix">Password</label>
        </div>

        <div className="ctrl right-align">
          <input className="btn blue darken-2" type="submit" value="Log in" />
        </div>
      </form>
    </div>
  )
}
