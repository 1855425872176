import React, {Component} from 'react';
import {fabric} from 'fabric';
import i from '../../assets/images/location.png'

export default class Canvas extends Component {
  canvas = null;

  createIcon = (icon) => {

    let {left, top, scaleX, scaleY,transform} = this.calcIconStyle(icon);

    fabric.Image.fromURL(icon.path, oImg => {
      oImg.left = left;
      oImg.top = top;
      oImg.id = icon.id;
      oImg.eventID = icon.eventID;
      oImg.subevtID = icon.subevtID;
      oImg.startTime = icon.startTime;
      oImg.endTime = icon.endTime;
      oImg.scaleX = scaleX || 0.4;
      oImg.scaleY = scaleY || 0.4;
      oImg.selectable = true;
      oImg.on('selected', this.onSelected);
      oImg.on('modified', this.onUpdate);
      this.canvas.add(oImg);
    });
  };

  calcIconStyle = (icon) => {
    let currVideoWidth = document.querySelector('.project-video').offsetWidth;
    let currVideoHeight = document.querySelector('.project-video').offsetHeight;
    const createdVideoWidth = icon.createdVideoWidth ? icon.createdVideoWidth : this.props.videoWidth;
    const createdVideoHeight = icon.createdVideoHeight ? icon.createdVideoHeight : this.props.videoHeight;

    let left = currVideoWidth * icon.left / createdVideoWidth;
    let top = currVideoHeight * icon.top / createdVideoHeight;
    let scaleX = icon.scaleX * currVideoWidth / createdVideoWidth;
    let scaleY = icon.scaleY * currVideoHeight / createdVideoHeight;
    let transform = `rotate(${icon.angle}deg)`;
    return {left, top, scaleX, scaleY,transform};
  }

  setIcons = () => {
    (Array.from(this.canvas._objects)).forEach(obj => {
      if (!this.props.icons.find(icon => obj.id == icon.id)) this.canvas.remove(obj);
    });

    this.props.icons.forEach(icon => {
    if (!this.canvas._objects.find(obj => obj.id === icon.id)) this.createIcon(icon);
   });
  };

  onUpdate = e => {
    this.props.updateIcon(e.target);
  };

  deleteIcons = () => {
    (Array.from(this.canvas._objects)).forEach(obj => this.canvas.remove(obj))
  }

  selectIcon = () => {
    this.canvas.getObjects().forEach(obj => {
      if (obj.id == this.props.selectedIcon) {
        this.canvas.setActiveObject(obj);
        this.canvas.renderAll();
      }
    });
  }

  onSelected = e => {
    let icons = document.querySelectorAll('.selected-icon-indicator');
    icons.forEach(icon => {
      if (icon.id !== this.canvas.getActiveObject().id) icon.style.visibility = 'hidden';
      else icon.style.visibility = 'visible';
    });
  }

  detectVisibleElements = () => {
    this.props.shelfEvents.forEach(evt => {
      if (!this.canvas._objects.find(obj => obj.id === evt.id) && evt.start <= this.props.currentTime && evt.end >= this.props.currentTime) this.createIcon(evt);

      this.canvas._objects.forEach(obj => {
        if (evt.id === obj.id && (evt.start > this.props.currentTime || evt.end < this.props.currentTime)) this.canvas.remove(obj);
      });
    });
  };

  componentDidMount() {
    this.canvas = new fabric.Canvas('icons-canvas', {});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.toggleIconsUpdate !== prevProps.toggleIconsUpdate) {
      this.setIcons();
    }
    if (this.props.selectedIcon !== prevProps.selectedIcon) this.selectIcon();
  }

  render () {
    return (
      <canvas id="icons-canvas" width={this.props.videoWidth} height={this.props.videoHeight}></canvas>
    );
  }
}
