import React, { useEffect, useState } from 'react';
import Counter from './Counter.jsx';
import { events } from '../modules/events.jsx'
import icon from '../../assets/images/arrows/up.png';

export default function Subevent({ subevent, setSubevent, deleteSubevent, onSelectIcon }) {
  let [indicatorStyle, setIndicatorStyle] = useState({ visibility: 'hidden' });
  let [dlStyle, setDLStyle] = useState({ visibility: 'visible' });

  let setDLVisibility = isExit => {
    if (isExit)
      setDLStyle({ visibility: 'hidden' });
    else
      setDLStyle({ visibility: 'visible' });
  }

  useEffect(() => {
    setDLVisibility(subevent.isExit);
  }, []);


  let setIsExit = e => {
    let subevt = { ...subevent };
    subevt.isExit = e.target.checked;
    setSubevent(subevt);
    setDLVisibility(e.target.checked);
  };

  let setIsBack = e => {
    let subevt = { ...subevent };
    subevt.isBack = e.target.checked;
    setSubevent(subevt);
  };

  let getSubevtView = () => {
    switch (subevent.next) {
      case 'dl':
        return (
          <div className="ctrl dl next">
            <input type="text" placeholder="Direct link" value={subevent.directLink} name="directLink" onChange={(e) => { events.setEvtProp(e, subevent, setSubevent) }} style={dlStyle} />
            <input type="checkbox" checked={subevent.isExit} name="isExit" onChange={setIsExit} />
            <label>Exit</label>
          </div>
        );
      case 'intro+dl':
        return (
          <div className="ctrl intro-dl next">
            <input type="number" placeholder="Start" value={subevent.introStart} name="introStart" onChange={(e) => { events.setEvtProp(e, subevent, setSubevent) }} />
            <input type="number" placeholder="End" value={subevent.introEnd} name="introEnd" onChange={(e) => { events.setEvtProp(e, subevent, setSubevent) }} />
            <input type="text" placeholder="Direct link" value={subevent.directLink} name="directLink" onChange={(e) => { events.setEvtProp(e, subevent, setSubevent) }} style={dlStyle} />
            <input type="checkbox" checked={subevent.isExit} name="isExit" onChange={setIsExit} />
            <label>Exit</label>
          </div>
        );
      case 'continue':
        return (
          <div className="ctrl continue">
            <input type="number" placeholder="Continue at" value={subevent.continueAt} min="1" step="0.1" name="continueAt" onChange={(e) => { events.setEvtProp(e, subevent, setSubevent) }} />
            <span className="checkbox-wrapper">
              <input type="checkbox" checked={subevent.isBack} name="isBack" onChange={setIsBack} />
              <label>Back</label>
            </span>
          </div>
        );
      case 'loop':
        return (
          <div className="ctrl dl next">
            <input type="number" placeholder="Loop end time" value={subevent.endTime} name="endTime" onChange={(e) => { events.setEvtProp(e, subevent, setSubevent) }} />
          </div>
        );
    }
  };
  let [subevtView, setSubevtView] = useState(getSubevtView());

  useEffect(() => {
    setSubevtView(getSubevtView());
  }, [subevent]);

  let setNextEvent = e => {
    let subevt = { ...subevent };
    subevt.next = e.target.value;
    if (subevt.next === 'continue' || subevt.next === 'loop') subevt.isExit = false;
    setSubevent(subevt);
  }

  let selectIcon = () => {
    let icons = document.querySelectorAll('.selected-icon-indicator');
    icons.forEach(icon => {
      if (icon.id !== subevent.icon.id) icon.style.visibility = 'hidden';
      else icon.style.visibility = 'visible';
    });
    // setIndicatorStyle({visibility: 'visible'});
    onSelectIcon();
  }

  return (
    <div className="subevent">
      <div className="img-wrapper">
        <i className="material-icons green-text text-lighten-2 selected-icon-indicator" id={subevent.icon.id}>fiber_manual_record</i>
        <img src={subevent.icon.path} alt="Icon" onClick={selectIcon} />
      </div>
      <div className="ctrl">
        <input type="text" placeholder="Name" value={subevent.name} name="name" onChange={(e) => { events.setEvtProp(e, subevent, setSubevent) }} />
      </div>
      <div className="ctrl">
        <input type="text" placeholder="Label" value={subevent.label} name="label" onChange={(e) => { events.setEvtProp(e, subevent, setSubevent) }} />
      </div>
      <div className="ctrl">
        <p>Show label</p>
        <div className="switch" ><label>On Hover<input name="labelVisibility" defaultChecked={subevent.labelVisibility === "always" ? true : subevent.labelVisibility === undefined ? true : false} onChange={(e) => { events.setLabelVisibility(e, subevent, setSubevent) }} type="checkbox" /><span className="lever"></span>Always</label></div>
      </div>
      <div className="ctrl">
        <select name="subevent-type" onChange={setNextEvent}>
          <option value="dl" selected={subevent.next === "dl"}>Direct link</option>
          <option value="intro+dl" selected={subevent.next === "intro+dl"}>Intro + direct link</option>
          <option value="continue" selected={subevent.next === "continue"}>Continue</option>
          <option value="loop" selected={subevent.next === "loop"}>Loop video</option>
          <option value="noAction" selected={subevent.next === "noAction"}>No action</option>
        </select>
      </div>
      {
        subevtView
      }
      <div className="subevt-ctrls">
        <i className="material-icons grey-text link" title="Remove subevent" onClick={deleteSubevent}>clear</i>
      </div>
    </div>
  )
}
