import React from 'react';
import {Link} from 'react-router-dom';

export default function ProjectRowControls ({project, onDeleteProject, onDuplicateProject}) {
  return (
    <td className="project-ctrls">
      <Link to={'project/' + project.id}>
        <i className="material-icons link blue-text text-darken-2" title="Edit Project">edit</i>
      </Link>
      <i className="material-icons link blue-text text-darken-2" title="Duplicate Project" onClick={() => onDuplicateProject(project)}>file_copy</i>
      <i className="material-icons link red-text text-lighten-1" title="Delete Project" onClick={() => onDeleteProject(project)}>delete</i>
    </td>
  )
}
