import React, {useState, useEffect} from 'react';
import Editor from './TextEditor'

export default function BoardEventOverlay({boardEvt, setBoardEvtText, onEditBoardEvt, videoWidth, videoHeight}) {
  let [style, setStyle] = useState({display: 'none'});
  let [text, setText] = useState('');
  let showBoard = false;
  if(boardEvt){
     showBoard = boardEvt.showEdit ? boardEvt.showEdit : false
  }
  useEffect(() => {
    let width = videoWidth + 'px';
    let height = videoHeight + 'px';
    let newStyle = boardEvt ? {display: 'block'} : {display: 'none'};

    if(boardEvt){
      let text = boardEvt.text;
      let opacity = boardEvt.opacityVideoLevel ?  boardEvt.opacityVideoLevel + '%' : 20 + '%';
      setText(text);
      setStyle({...newStyle, width, height, opacity});
    }else{
      setStyle({...newStyle, width, height});
    }
  }, [boardEvt]);

  return (

    <div>
      <div className="be-overlay" style={style}>

      </div>
      {showBoard ? <div className="background-panel"></div> : ''}
      {showBoard ? <Editor onEditBoardEvt={onEditBoardEvt} setBoardEvtText={setBoardEvtText} boardEvt={boardEvt}/> : ''}
    </div>
  )
}
