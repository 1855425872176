import React, {useEffect} from 'react';
import Dropzone from '../project/Dropzone.jsx';
import {Materialize} from '../../helpers/Materialize.js';

export default function BackVideoModal ({hideModal, project, changeBackVideo}) {
  useEffect(() => {
    Materialize.initTabs({swipeable: true});
  });

  return (
    <div className="card">
      <div className="card-title">
        <h6>Set Back Video</h6>
      </div>

      <div className="back-video-title">
        <p>Currently set: </p>
        <a href={project.backVideo} target="_blank"> {project.backVideo} </a>
      </div>


      <div className="back-video-body">
        <div id="upload">
          <Dropzone isSaved={!!project.id} uploadVideo={changeBackVideo}/>
        </div>
     </div>

     <div className="back-video-footer">
        <button className="btn blue darken-1" onClick={hideModal}> OK </button>
     </div>
   </div>
  )
}
