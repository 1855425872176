import M from "materialize-css";

export const Materialize = {
  initCollapsibles() {
    var elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems, {accordion: false});
  },

  closeAllCollapsibles() {
    let collapsibles = document.getElementsByClassName('collapsible');
    for (let i = 0; i < collapsibles.length; i++) {
      let instance = M.Collapsible.getInstance(collapsibles[i]);
      instance.close();
    }
  },

  initMenu() {
    let elems = document.querySelectorAll('.fixed-action-btn');
    M.FloatingActionButton.init(elems, {hoverEnabled: true, toolbarEnabled: false});
    M.FloatingActionButton.getInstance(elems[0]).open();
  },

  initTabs(options = {}) {
    let elems = document.querySelectorAll('.tabs');
    M.Tabs.init(elems, options);
  }
}
