import React, {useEffect, useState} from 'react';
import {g} from '../../helpers/global.js';
import Subevent from './Subevent.jsx';
import {events} from '../modules/events.jsx'

export default function StopEvent({onDeleteEvent, subevents, selected, event, setEvent, onSelectEvent, videoDuration, onSelectIcon}) {
  let [classes, setClasses]= useState('stop-evt with-subevts evt');

  useEffect(() => {
    let classesCurr = 'stop-evt with-subevts evt' + (selected == event.id ? ' active' : '');
    setClasses(classesCurr);
  }, [selected]);


  return (
    <div className={classes} onClick={onSelectEvent}>
      <div className="with-subevts-left">
        <i className="material-icons grey-text evt-icon" title="Stop event">traffic</i>
        <input type="number" placeholder="Time" className="time" value={event.startTime} onChange={(e)=>{events.setEvtProp(e,event,setEvent)}} name="startTime" step="0.1" min="1" max={Math.floor(videoDuration)}/>
        {
          // <input type="text" placeholder="Name"  value={event.name} onChange={setEvtProp} name="name"/>
        }
      </div>

      <div className="with-subevts-right">
      {
        subevents.map((subevt, i) => {
          return <Subevent key={subevt.id}
                           subevent={subevt}
                           setSubevent={subevent => events.setSubevent(i ,event, subevent,setEvent)}
                           deleteSubevent={() => events.deleteSubevent(subevt,event,setEvent)}
                           onSelectIcon={e => onSelectIcon(e, subevt.icon)}
                           />
        })
      }
      </div>

      <div className="evt-ctrl">
        <i className="material-icons grey-text link" title="Delete whole event" onClick={onDeleteEvent}>delete</i>
      </div>
    </div>
  )
}
