import {g} from '../../helpers/global.js';
export const events = {

  setEvtProp (e,event,setEvent){
    let evt = {...event};
    let value = e.target.value;
    if(e.target.type === 'number'){
      value = parseFloat(value);
    }
    if(e.target.name === "instructionsIntro"){
      value = e.target.checked ? true : false ;
      if(e.target.checked) {
        evt["startTime"] = 0;
      }
    }

    if(e.target.name === "startTime" && value > 0){
      evt["instructionsIntro"] = false;
    }
    evt[e.target.name] = value;
    setEvent(evt);
  },

    setSubevent (i,event,subevent,setEvent){
       let evt = {...event};
       evt.subevents[i] = subevent;
       setEvent(evt);
     },
   setLabelVisibility(e,event,setEvent){
     let evt = {...event};
     let value = e.target.checked ? 'always' : 'onHover' ;
     evt[e.target.name] = value;
     setEvent(evt);
   },

    deleteSubevent (subevt,event,setEvent) {
      const resp = window.confirm("Are you sure ?!");
      if (resp) {
        let evt = g.deepCopy(event);
        let i = g.findIndexByProp(evt.subevents, 'id', subevt.id);
        evt.subevents.splice(i, 1);
        setEvent(evt);
      }
    }
}
