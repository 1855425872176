import React, {Component} from 'react';
import Navigation from '../Navigation.jsx';
import ProjectsList from './ProjectsList.jsx';
import Modal from '../modal/Modal.jsx';
import DeleteProject from '../modal/DeleteProject.jsx';
import spinnerGif from '../../assets/images/spinner.gif'
import {g} from '../../helpers/global.js';
import {connect} from 'react-redux';
import {createNewProject, deleteProject, duplicateProject, fetchProjects, openModal, hideModal} from '../../redux/actions.js';

class Projects extends Component {
  searchFor = '';

  constructor(props) {
    super(props);
    this.state = {
      filteredProjects: [],
      loading: true
    };
  };

  openDeleteModal = project => {
    let component = <DeleteProject project={project} hideModal={this.props.hideModal} deleteProject={this.onDeleteProject} />;
    this.props.openModal(component);
  };

  onDeleteProject = project => {
    this.props.hideModal();
    this.props.deleteProject(project)
      .then(response => {
        this.filterProjects();
      })
      .catch(err => {});

  }

  onDuplicateProject = project => {
    project = g.deepCopy(project);
    project.name += ' - copy';
    delete project.id;

    this.props.duplicateProject(project)
      .then(response => {})
      .catch(err => {})
      .finally(() => {
        this.setState({filteredProjects: this.props.projects});
      });
  };

  createNewProject = () => {
    this.props.createNewProject();
    this.props.history.push('/project/create-new');
  };

  filterProjects = () => {
    let projects = g.deepCopy(this.props.projects);
    let searchFor = document.getElementById('searchFor').value;
    projects = projects.filter(project => project.name.toLowerCase().indexOf(searchFor.toLowerCase()) > -1);
    this.setState({filteredProjects: projects});
  };

  render () {

    return (
      <div id="projects">
        <Modal />
        <Navigation />

        <div className="projects-title">
          <h4>Projects</h4>

          <button className="btn blue darken-2 btn-flex" onClick={this.createNewProject}>
            <i className="material-icons white-text">add</i>
            <span className="white-text">Create</span>
          </button>
        </div>

        <div className="projects-body">
        {this.state.loading ? <img src={ spinnerGif }></img> :
          <table className="responsive">
            <thead>
              <tr>
                <th className="filterable">
                  <i className="material-icons">search</i>
                  <input className="invisible-borders" id="searchFor" placeholder='Name' onFocus={e => e.target.placeholder=''} onBlur={e => e.target.placeholder='Name'} onKeyUp={this.filterProjects}/>
                </th>
                <th> Cell </th>
                <th> Next URL </th>
                <th></th>
              </tr>
            </thead>
            <ProjectsList projects={this.state.filteredProjects}
                          onDeleteProject={this.openDeleteModal}
                          onDuplicateProject={this.onDuplicateProject}/>
          </table>
          }
        </div>
      </div>
    );
  };

  componentDidMount() {
    this.props.fetchProjects()
      .then(response => this.setState({filteredProjects: this.props.projects, loading:false}));
  }
};

const mapStateToProps = state => {
  return {
    projects: state.projects
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchProjects: () => dispatch(fetchProjects()),
    deleteProject: project => dispatch(deleteProject(project)),
    duplicateProject: project => dispatch(duplicateProject(project)),
    openModal: modal => dispatch(openModal(modal)),
    hideModal: () => dispatch(hideModal),
    createNewProject: () => dispatch(createNewProject)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
