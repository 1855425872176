import React, {Component} from 'react';
import {Draggable} from '../../helpers/Draggable.js';
import {g} from '../../helpers/global.js';
import {responsiveness} from '../../helpers/responsiveness.js';
import {api} from '../../api';

export default class Icons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icons: []
    };
  }

  onStartDragging = (el, icon) => {
    let icons = g.deepCopy(this.state.icons);
    let newIcon = {...icon, id: 'icon' + new Date().getTime()};
    let options = {
      data: newIcon,
      onStartDragging: this.onStartDragging,
      onStopDragging: this.onStopDragging
    }

    let idx = g.findIndexByProp(icons, 'id', icon.id);
    icons.splice(idx, 0, newIcon);
    this.setState({icons});
    new Draggable(newIcon, options);
  }

  onStopDragging = (e, icon) => {
    let canvasPosition = document.querySelector('.canvas-container').getBoundingClientRect();
    let iconPosition = e.getBoundingClientRect();
    let icons = g.deepCopy(this.state.icons);
    let idx = g.findIndexByProp(icons, 'id', icon.id);
    if (iconPosition.bottom < canvasPosition.top || iconPosition.left > canvasPosition.right || iconPosition.right < canvasPosition.left || iconPosition.top > canvasPosition.bottom) {
      icons.splice(idx, 1);
      this.setState({icons});
      return;
    }

    icon.top = iconPosition.top;
    icon.left = iconPosition.left;
    icon.width = iconPosition.width;
    icon.height = iconPosition.height;
    icon.createdVideoWidth = canvasPosition.width;
    icon.createdVideoHeight = canvasPosition.height;

    icons.splice(idx, 1);
    this.setState({icons});
    this.props.createSubevent(icon);
  }

  render() {
    return (
      <div className="icons">
        {
          this.state.icons.map(icon => {
            let className = 'icon draggable-item' + (this.props.disabled ? ' disabled' : '');
            return (
              <img src={icon.path} className={className} id={icon.id} key={icon.id}/>
            )
          })
        }
      </div>
    )
  };

  componentDidMount() {

    api.fetchIcons()
      .then(response => {
        let icons = response.data.map((icon, i) => {
          return {
            path: icon,
            id: new Date().getTime() + i,
            left: 0,
            top: 0
          }});
          this.setState({icons});

      this.state.icons.forEach((icon, idx) => {
          let options = {
            data: icon,
            onStartDragging: this.onStartDragging,
            onStopDragging: this.onStopDragging
          }
          new Draggable(icon, options);
        });
      })


  };
}
