import axios from './config.js';

function getSessionParams () {
  let user = JSON.parse(localStorage.getItem('user'));
  return user ? {
    user_key: user.user_key,
    userid: user.userid
  } : null;
};

export const api = {
  fetchProjects (data) {
    return axios({
      url: 'projects',
      params: getSessionParams()
    });
  },

  saveProject (data) {
    data = {...data, ...getSessionParams()};
    return axios({
      url: 'project/save',
      method: 'POST',
      data
    });
  },

  deleteProject (data) {
    data = {...data, ...getSessionParams()};
    return axios({
      url: 'project/delete',
      method: 'POST',
      data
    });
  },

  uploadMedia (data) {
    let user = getSessionParams();
    data.append('userid', user.userid);
    data.append('user_key', user.user_key);

    return axios({
      url: 'project/file/upload',
      method: 'POST',
      headers: {'Content-type': 'multipart/form-data'},
      data
    });
  },

  deleteMedia (data) {
    data = {...data, ...getSessionParams()};
    return axios({
      url: 'project/file/delete',
      method: 'POST',
      data
    });
  },

  deleteAllMedia (data) {
    return axios({
      url: 'project/files/delete',
      method: 'POST',
      data
    });
  },

  login(data) {
    return axios({
      url: 'user/login',
      method: 'POST',
      data
    });
  },

  logout(data) {
    data = {...data, ...getSessionParams()};
    return axios({
      url: 'user/logout',
      method: 'POST',
      data
    });
  },

  fetchIcons(params) {
    params = getSessionParams();
    return axios({
      url: 'icons',
      params
    });
  }
}
