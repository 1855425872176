import React, {useState, useEffect} from 'react';
import {events} from '../modules/events.jsx'

export default function BoardEvent({onDeleteEvent, selected, onSelectEvent, event, onEditBoardEvt, setEvent, id, videoDuration}) {
  let [classes, setClasses]= useState('board-evt evt');

  useEffect(() => {
    let classesCurr = 'board-evt evt' + (selected == id ? ' active' : '');
    setClasses(classesCurr);
  }, [selected]);

  return (
    <div className={classes} onClick={onSelectEvent}>

      <i className="material-icons grey-text evt-icon">post_add</i>

      <input
        type="number"
        placeholder="Time"
        className="time"
        name="startTime"
        onChange={(e)=>{events.setEvtProp(e,event,setEvent)}}
        value={event.startTime}
        step="0.1"
        min="1"
        max={Math.floor(videoDuration)}/>

      <input
        className="boardName"
        type="text"
        placeholder="Name"
        name="name"
        onChange={(e)=>{events.setEvtProp(e,event,setEvent)}}
        value={event.name}/>
      <div
        className="video-opacity-wrapper">Video opacity
        <p className="range-field">
        <input name="opacityVideoLevel" type="range" id="opacityVideoLevel" min="0" max="100" value={event.opacityVideoLevel ? event.opacityVideoLevel : 20} onChange={(e) => events.setEvtProp(e,event,setEvent)} />
        </p>
      </div>
      <div
        onClick={(e)=>{e.stopPropagation();}}
        className="ctrl">

        <p>Instructions</p>

        <div className="switch" >
          <label>Off<input
            disabled={selected !== id}
            name="instructionsIntro"
            checked={event.instructionsIntro}
            onChange={(e) => {events.setEvtProp(e,event,setEvent)}}
            type="checkbox" /><span className="lever">
          </span>On</label>
        </div>

      </div>

      <button
        className= {`waves-effect waves-light btn-small ${selected !== id ? 'disabled' : ''}`}
        onClick={(e)=>{onEditBoardEvt(e,event)}}>Edit
      </button>

      <div
        style={{display: `${event.showEdit ? 'block' : 'none'}`}}
        className="opacity-level-wrapper">
        Board Opacity<p className="range-field">
        <input name="opacityLevel" type="range" id="opacityLevel" min="0" max="100" value={event.opacityLevel ? event.opacityLevel : 100} onChange={(e) => events.setEvtProp(e,event,setEvent)} /></p>
      </div>

      <i
        className="material-icons grey-text link"
        title="Delete whole event"
        onClick={onDeleteEvent}> delete </i>

    </div>
  )
}
